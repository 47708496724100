// private config

const development = {
    purecloud: {
        clientId: '32c94048-a19e-4a7c-8a73-ace805cb6837',
    },
    product: 'purecloud',
    endpoints: {
    },

    URL_list : {
        Agent_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentPerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Queue_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentQueueAggregatesMagellan_Healthv3_0/AgentQueuePerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_KPIs: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashbaordKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Summary: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashboardSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Threshold: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueuePerformanceDashboardThreshold?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Analytics: "https://10az.online.tableau.com/t/rba13611/views/ContactCenterAnalyticsMagellan_Healthv3_0/ContactCenterAnalytics?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Interactions_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardInteractions?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_KPIs_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Performance: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementPerformance?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Evaluation: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementEvaluation?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Details_Activity_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentDetailsActivityAggregatesMagellan_Healthv3_0/AgentDetailsActivityReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Status_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentSessionAggregatesMagellan_Healthv3_0/AgentDetailsReportStatusActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Interaction_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentDetailsReportInteractionActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Summary_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueueDetailsReportSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Accepted_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportAgentAccepted?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Abandoned_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportQueueAbandon?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Conversation_Details_Report: "https://10az.online.tableau.com/t/rba13611/views/ConversationDetailsMagellan_Healthv3_0/ConversationDetails/7033209f-eb81-475a-9ecb-6cfce8baf5f9/2bd40374-9fad-4fb2-935c-8c01c5488b6a?:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Report:"https://gen1tabpgw1.genesys.com/t/OneGenesys/views/QualityManagementReportv3_0_16505646519330/EvaluationDetailsReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"       

    },

    filters : {
        Agent_Performance: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Queue_Performance: {Agent_Name : true, Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_KPIs: {Customer_Segment : true, Media_Type : true ,Service_Type: true, Service_Sub_Type : true , Queue_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Summary: {Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Threshold: {Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Analytics: {Customer_Segment : true, Service_Type: true, Service_Sub_Type : true , Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Interactions_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_KPIs_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Performance: {Queue_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Evaluation: {Queue_Name : true, Form_Name : true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Details_Activity_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Status_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Interaction_Report: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Summary_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Accepted_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Abandoned_Report: {Queue_Name : true,Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Conversation_Details_Report: {Conversation_Id : true, Media_Type: true, Wrap_Up_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true, Column_Picker : false },
        Quality_Management_Report:{Queue_Name : true, Form_Name : true , PreSet_Date : true, Start_Date : true, End_Date : true }        
    }
}

const production = {
    purecloud: {
        clientId: '32c94048-a19e-4a7c-8a73-ace805cb6837',
    },
    product: 'purecloud',
    endpoints: {},

    URL_list : {
        Agent_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentPerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Queue_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentQueueAggregatesMagellan_Healthv3_0/AgentQueuePerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_KPIs: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashbaordKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Summary: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashboardSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Threshold: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueuePerformanceDashboardThreshold?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Analytics: "https://10az.online.tableau.com/t/rba13611/views/ContactCenterAnalyticsMagellan_Healthv3_0/ContactCenterAnalytics?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Interactions_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardInteractions?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_KPIs_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Performance: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementPerformance?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Evaluation: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementEvaluation?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Details_Activity_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentDetailsActivityAggregatesMagellan_Healthv3_0/AgentDetailsActivityReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Status_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentSessionAggregatesMagellan_Healthv3_0/AgentDetailsReportStatusActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Interaction_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentDetailsReportInteractionActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Summary_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueueDetailsReportSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Accepted_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportAgentAccepted?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Abandoned_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportQueueAbandon?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Conversation_Details_Report: "https://10az.online.tableau.com/t/rba13611/views/ConversationDetailsMagellan_Healthv3_0/ConversationDetails/7033209f-eb81-475a-9ecb-6cfce8baf5f9/2bd40374-9fad-4fb2-935c-8c01c5488b6a?:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Report:"https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementEvaluationDetailsReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"       

    },

    filters : {
        Agent_Performance: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Queue_Performance: {Agent_Name : true, Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_KPIs: {Customer_Segment : true, Media_Type : true ,Service_Type: true, Service_Sub_Type : true , Queue_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Summary: {Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Threshold: {Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Analytics: {Customer_Segment : true, Service_Type: true, Service_Sub_Type : true , Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Interactions_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_KPIs_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Performance: {Queue_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Evaluation: {Queue_Name : true, Form_Name : true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Details_Activity_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Status_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Interaction_Report: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Summary_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Accepted_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Abandoned_Report: {Queue_Name : true,Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Conversation_Details_Report: {Conversation_Id : true, Media_Type: true, Wrap_Up_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true, Column_Picker : false },
        Quality_Management_Report:{Queue_Name : true, Form_Name : true , PreSet_Date : true, Start_Date : true, End_Date : true }        
    }
}

const test = {
    purecloud: {
        clientId: '32c94048-a19e-4a7c-8a73-ace805cb6837',
    },
    product: 'purecloud',
    endpoints: {},
    URL_list : {
        Agent_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentPerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Queue_Performance: "https://10az.online.tableau.com/t/rba13611/views/AgentQueueAggregatesMagellan_Healthv3_0/AgentQueuePerformanceDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_KPIs: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashbaordKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Summary: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueuePerformanceDashboardSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Performance_Threshold: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueuePerformanceDashboardThreshold?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Analytics: "https://10az.online.tableau.com/t/rba13611/views/ContactCenterAnalyticsMagellan_Healthv3_0/ContactCenterAnalytics?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_Interactions_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardInteractions?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Contact_Center_KPIs_Dashboard: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Performance: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementPerformance?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Evaluation: "https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementEvaluation?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Details_Activity_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentDetailsActivityAggregatesMagellan_Healthv3_0/AgentDetailsActivityReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Status_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentSessionAggregatesMagellan_Healthv3_0/AgentDetailsReportStatusActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Agent_Interaction_Report: "https://10az.online.tableau.com/t/rba13611/views/AgentAggregatesMagellan_Healthv3_0/AgentDetailsReportInteractionActivity?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Summary_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/QueueDetailsReportSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Accepted_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportAgentAccepted?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Queue_Agent_Abandoned_Report: "https://10az.online.tableau.com/t/rba13611/views/QueueAbandonandAcceptedAggregatesMagellan_Healthv3_0/QueueDetailsReportQueueAbandon?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Conversation_Details_Report: "https://10az.online.tableau.com/t/rba13611/views/ConversationDetailsMagellan_Healthv3_0/ConversationDetails/7033209f-eb81-475a-9ecb-6cfce8baf5f9/2bd40374-9fad-4fb2-935c-8c01c5488b6a?:display_count=n&:showVizHome=n&:origin=viz_share_link",
        Quality_Management_Report:"https://10az.online.tableau.com/t/rba13611/views/QualityManagementMagellan_Healthv4_0/QualityManagementEvaluationDetailsReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"       

    },

    filters : {
        Agent_Performance: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Queue_Performance: {Agent_Name : true, Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_KPIs: {Customer_Segment : true, Media_Type : true ,Service_Type: true, Service_Sub_Type : true , Queue_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Summary: {Queue_Name: true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Performance_Threshold: {Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Analytics: {Customer_Segment : true, Service_Type: true, Service_Sub_Type : true , Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_Interactions_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Contact_Center_KPIs_Dashboard: {Agent_Name : false, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Performance: {Queue_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Quality_Management_Evaluation: {Queue_Name : true, Form_Name : true, PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Details_Activity_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Status_Report: {Agent_Name : true, Media_Type : false , PreSet_Date : true, Start_Date : true, End_Date : true },
        Agent_Interaction_Report: {Agent_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Summary_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Accepted_Report: {Queue_Name : true, Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Queue_Agent_Abandoned_Report: {Queue_Name : true,Media_Type : true , PreSet_Date : true, Start_Date : true, End_Date : true },
        Conversation_Details_Report: {Conversation_Id : true, Media_Type: true, Wrap_Up_Name: true, PreSet_Date : true, Start_Date : true, End_Date : true, Column_Picker : false },
        Quality_Management_Report:{Queue_Name : true, Form_Name : true , PreSet_Date : true, Start_Date : true, End_Date : true }        
    }
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'test': config = test; break;
    default: break;
}

export default config
export const headerTitle = "A3S Tableau Web App"