import './Home.css'
import React from 'react'
import {useState, useEffect } from 'react';
import { Container } from 'reactstrap'
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import AppContext from '../appContext/AppContext';
import Tableau from '../../js/tableau';
import SubscribeModal from '../subscribe/Subscribe';
import TableauLogin from '../tableau-login/TableauLogin';

const Home = () => {

    const [activeViz, setActiveViz] = useState('Contact_Center_KPIs_Dashboard');
    
    return (
        
        <div>
            <AppContext.Provider value={[activeViz, setActiveViz]}>
            <main className="mt-5 pt-3">
            <div className="container-fluid custom-fluid">
            <Header />
            <Sidebar />
            <div className="row">
                <div col-md-12 id="vizContainer" className='vizContainer'>
                </div>
            </div>
            {/*Footer containing version*/}
            </div>
        </main>
            </AppContext.Provider>
        </div>
    )
}

export default Home