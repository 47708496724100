/* eslint-disable */
import React, {useState } from 'react';
import '../../css/bootstrap.min.css';
import '../../css/bootstrap.min.css.map';
import '../../css/style.css';
import '../../css/custom.css';
import '../../js/bootstrap.bundle.min.js';
import Tableau from '../../js/tableau.js';
import Filter from '../filter/filterModal';
import SubscribeModal from '../subscribe/Subscribe';

const Toolbar = (props) => { 

    const [filterValuesList, setFilterValuesList] = useState({});
    const [filterDefaultList, setFilterDefaultList] = useState({});
    const [parameterCurrentList, setparameterCurrentList] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showSubscribe, setSubscribeModal] = useState(false);


    const initValues = async() => {

    const promises = [Tableau.retrieveAllFilterValuesFromWorkbook(),
        Tableau.retrieveDefaultFilterValuesFromWorkbook(),
        Tableau.retrieveCurrentParameters()]
    const responses = await Promise.all(promises)
    for (const response of responses) {
        switch (response.name) {
            case 'retrieveAllFilterValuesFromWorkbook': setFilterValuesList(response.value)
              break;
            case 'retrieveDefaultFilterValuesFromWorkbook': setFilterDefaultList(response.value)
              break;
            case 'retrieveCurrentParameters': setparameterCurrentList(response.value)
              break;
              default:
                break;
            }
        }
        setShowModal(true)
        
    }

    const initSubscribe = async() =>
    {
      setSubscribeModal(true)
    }

    return (      
            
                <div className="col-md-6 toolbar-padding">
                  <button type="button"  className="btn btn-secondary btn-custom" data-bs-toggle="modal" id="filterBtn" hidden title="Filter" onClick={initValues}>
                    <i className="bi bi-funnel"></i>
                  </button>
                  <button type="button" id="edit" className="btn btn-secondary btn-custom" data-bs-toggle="tooltip" hidden data-bs-placement="left" title="Edit" onClick={Tableau.editDashboard}>
                    <i className="bi bi-pencil"></i>
                  </button> 
                  <button type="button" className="btn btn-secondary btn-custom" data-bs-toggle="tooltip" hidden data-bs-placement="right" id="shareBtn" title="Share" onClick={Tableau.shareView}>
                        <i className="bi bi-share"></i>
                  </button>  
                    <div className="dropdown">
                        <button className="btn btn-secondary btn-custom" hidden id="exportBtn"> <i className="bi bi-cloud-arrow-down"></i></button>
                        <div className="dropdown-content">
                            <a onClick={Tableau.exportToPDF}>Export to PDF</a>
                            <a onClick={Tableau.exportToCSV}>Export to CSV</a>
                            <a onClick={Tableau.exportToExcel}>Export to Excel </a>    
                            <a onClick={Tableau.exportToPP}>Export to PP </a>   
                            <a onClick={Tableau.exportToImage}>Export to Image </a>                       
                        </div>
                    </div>
                    {/* <button type="button" className="btn btn-secondary btn-custom" data-bs-toggle="modal" id="subscribeModal" title="Subscribe" onClick={initSubscribe}>
                    <i className='fa fa-envelope' aria-hidden="true"></i>
                  </button>   */}   
                    <button type="button" className="btn btn-secondary btn-custom" data-bs-toggle="tooltip" id="refreshBtn" hidden title="Refresh" onClick={Tableau.refreshView}>
                        <i className="bi bi-arrow-clockwise"></i>
                  </button>        
                  
          {showModal?<Filter
                        filterValuesList = {filterValuesList}
                        filterDefaultList = {filterDefaultList}
                        parameterCurrentList = {parameterCurrentList}
                        showModal={showModal} 
                        toggle = {()=>setShowModal(false)}  /> : null}  
         {showSubscribe?<SubscribeModal 
                    showSubscribe = {showSubscribe}
                    toggle = {()=>setSubscribeModal(false)} />: null}

                    
            </div>
 
    )
}

export default Toolbar