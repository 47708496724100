/* eslint-disable */
import $ from 'jquery';
import config from '../config';


var containerDiv, viz, activeSheet, event, filterValue = [], box,agentNameList;
let worksheet;

event = {};
var viz_options = {
    onFirstInteractive: function() {
        $("#edit").show(); 
    }
};

window.addEventListener('resize', resizeViz);

export default {  

loadViz: (event)  => 
    {    
        $("#filterBtn").prop('hidden',true);
        $("#edit").prop('hidden',true);
        $("#shareBtn").prop('hidden',true);
        $("#exportBtn").prop('hidden',true);
        $("#refreshBtn").prop('hidden',true);

        let viz_url, viz_name;

    containerDiv = document.getElementById("vizContainer");
    if (event == undefined || event == 0) {
        viz_url = "https://10az.online.tableau.com/t/rba13611/views/QueueAggregatesandContactCenterMagellan_Healthv3_0/ContactCenterDashboardKPIs?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
        viz_name = "Contact_Center_Analytics"
    }
    else if(event.edit){
        viz_url = event.viz_url;
    }
     else {
        document.getElementById("dashboard-title").innerHTML = event.currentTarget.getAttribute('view-title');
        viz_url = config.URL_list[event.currentTarget.getAttribute('data-view')];
        viz_name = event.currentTarget.getAttribute('data-view')
    }
    viz_options = {
        showTabs: false,
        hideToolbar: true,
        hideTabs: true,
        onFirstInteractive: function(){
         //alert("Viz Loaded");   
         $("#filterBtn").prop('hidden',false);
         $("#edit").prop('hidden',false);
         $("#shareBtn").prop('hidden',false);
         $("#exportBtn").prop('hidden',false);
         $("#refreshBtn").prop('hidden',false);
        }
    };
    loadView(containerDiv, viz_url, viz_options);
    return viz_name
},
refreshView: ()  =>  
{    
    console.log("Refershing")    
    viz.refreshDataAsync(); 
    refershMsg();  
},
exportToPDF: () =>
{   
    viz.showExportPDFDialog();
},
exportToPP: () =>
{
    viz.showExportPowerPointDialog();
},
exportToCSV: () =>
{
    viz.showExportCrossTabDialog();
},
exportToExcel: () =>
{
    viz.exportCrossTabToExcel();
},
exportToImage: () =>
{
    viz.showExportImageDialog();
},
shareView: () =>
{
    viz.showShareDialog();
},
editDashboard: () =>
{
    let edit_url,edit_options;
    event.edit = true;

    try
    {
        viz.getCurrentUrlAsync().then(function(current_url) {
            $("#edit").hide();  
            event.viz_url = current_url;   
            edit_url = current_url.split('?')[0].replace('/views', '/authoring');        
            console.log(edit_url);
            edit_options = {
                hideTabs: true,
                hideToolbar: true,
                onFirstInteractive: function() {
                    var iframe = document.querySelectorAll('iframe')[0];
                    iframe.onload = function() {
                        // Getting the URL post exit from web edit window
                        $("#edit").show();
                        viz.getCurrentUrlAsync().then(function(current_url) {
                            console.log(current_url);
                            loadViz();
                        })
                    }
                }
            };
            loadView(containerDiv, edit_url, edit_options);
        })
    }
    catch(e)
    {
        console.log("Inside Edit Dashboard Function")
        console.log(e)
        alertMsg();
    }
    
    
},

applyParameterToWorksheet: (paramName,paramValue) =>
{  
    try
    {
        let customDates = String(paramValue).includes('-');    
        if(paramValue != 'Custom Date Range')
        {
            $("#start_date").prop('disabled',true);
            $("#end_date").prop('disabled',true); 
        }    
        else
        {
            $("#start_date").prop('disabled',false);
            $("#end_date").prop('disabled',false); 
        }
        if(customDates == true)
        {
            $("#start_date").prop('disabled',false);
            $("#end_date").prop('disabled',false); 
        }
        viz.getWorkbook().changeParameterValueAsync(paramName, paramValue); 
    }  
    catch(e)
    {
        console.log("Inside apply Parameter To Worksheet Function")
        console.log(e);
        alertMsg();
    }
       

},

retrieveCurrentParameters: async() =>
{   
    try
    {
        let paramval = {};
        let paramvalAll = {};
        await viz.getWorkbook().getParametersAsync().then(function(paramObjs) {
                            for (var i = 0; i < paramObjs.length; i++) {
                                                try {
                                                                var name = paramObjs[i].getName();
                                                                paramval[name] = paramObjs[i].getCurrentValue();                                                            
                                                } catch (e) { console.log("An Error Occured while fetching parameter values")
                                            console.log(e) }
                                }
                        
                });
    
                 for (var key of Object.keys(paramval)){
                    paramvalAll[key]=  { "label": paramval[key].formattedValue,
                "value": paramval[key].value
            }           
                      
                 };
    
                 console.log(paramvalAll)
                        return  {'name': 'retrieveCurrentParameters', 'value' : paramvalAll}
    }
    catch(e)
    {
        console.log("Inside retrieve current parameters function")
        console.log(e);
        alertMsg();
    }
   
},

applyFiltertoWorkbook :(appliedValue, measureName) =>
{
    try
    {
            console.log("Apply Filter Worksheet", appliedValue, measureName)
            activeSheet = viz.getWorkbook().getActiveSheet();        
            worksheet = activeSheet.getWorksheets();
            if(appliedValue == 'All')
            {
                for (let i = 0; i< worksheet.length; i++)
                {   if(worksheet[i].getName() != 'Shadow_Filter')
                    worksheet[i].applyFilterAsync(measureName, "", window.tableau.FilterUpdateType.ALL)
                }  
            }
        else{
            for (let i = 0; i< worksheet.length; i++)
            { 
                if(worksheet[i].getName() != 'Shadow_Filter'){
                    worksheet[i].applyFilterAsync(measureName, appliedValue, window.tableau.FilterUpdateType.REPLACE)
                }   
            }    
        }
    }
    catch(e)
    {
        console.log("Inside apply filter to Workbook function")
        console.log(e)
        alertMsg()
    }
    
},


retrieveAllFilterValuesFromWorkbook: async () =>
 { 
    try
    {
        $(".btn").blur(); // To Remove clicked Button focus 
        console.log("Calling retrieveAllFilterValuesFromWorkbook ")
        let shadowWorkbook;
        let allFilterValue = {}
        shadowWorkbook = viz.getWorkbook().getActiveSheet().getWorksheets().get("Shadow_Filter")        
        allFilterValue = await retrieveFilter(shadowWorkbook)        
        return {'name': 'retrieveAllFilterValuesFromWorkbook', 'value' : allFilterValue}
    }  
    catch(e)
    {
        console.log("Inside retrieve all filter Values from Workbook Function")
        console.log(e)
        alertMsg()
    }
    
                    
 },
 

 retrieveDefaultFilterValuesFromWorkbook:  async() =>
  {
      try
      {
        console.log("Calling retrieveDefaultFilterValuesFromWorkbook ")
        let activeWorkbook;
        let allFilterValue = {}
        activeWorkbook = viz.getWorkbook().getActiveSheet().getWorksheets()[0]
        if (activeWorkbook.getName() == 'Shadow_Filter')
            activeWorkbook = viz.getWorkbook().getActiveSheet().getWorksheets()[1]
        allFilterValue = await retrieveFilter(activeWorkbook)
        return  {'name': 'retrieveDefaultFilterValuesFromWorkbook', 'value' : allFilterValue}                        
      }
      catch(e)
      {
        console.log("Inside retrieve Default Filter Values From Workbook function")
        console.log(e)
        alertMsg()
      }
    }
    
  
  }
  

async function retrieveFilter (workbookActive)
{
    try
    {
        let filterval = {}
        let filterValuesAll = {}
        await workbookActive.getFiltersAsync().then(function(FilterObjs) {  
            for (var i = 0; i < FilterObjs.length; i++) {
                            try {
                                            var name = FilterObjs[i].getFieldName();
                                            if ((FilterObjs[i].getFilterType()) == 'categorical')
                                                filterval[name] = FilterObjs[i].getAppliedValues();
                            } catch (e) { console.log("An Error Occured while fetching filter values")
                        console.log(e) }
                        
            }});

                        for (var key of Object.keys(filterval)){
                            var formattedjson = filterval[key].map( function(value) {
                            var info = { "label": value.formattedValue,
                                        "value": value.value
                                        }
                            return info;
                        });
                            
                            filterValuesAll[key] = formattedjson
                            
                        }
                    return filterValuesAll
    }
    catch(e)
    {
        console.log("Inside retrieve filter Function")
        console.log(e)
        alertMsg()
    }
    
}


function alertMsg()
{
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong, please contact Genesys for the assistance',
    })
}

function refershMsg()
{
    $(".btn").blur(); // To Remove clicked Button focus 
    Swal.fire({
        icon: 'info',
        text: 'Please wait until the page refreshes',
        timer: 1500
    })
}

// Loading the visualization
function loadView(containerDiv, url, options) {
    try
    {
        if (viz) {
            viz.dispose();
        }
        viz = new window.tableau.Viz(containerDiv, url, options);
    }
    catch(e)
    {
        console.log("Inside load view Function")
        console.log(e)
        alertMsg()
    }
    
}

function resizeViz() {
    var width = document.getElementById("vizContainer").clientWidth;
    var height = document.getElementById("vizContainer").clientHeight;
    viz.setFrameSize(width, height);
}

$(".btn").mouseup(function(){
    $(this).blur();
})

