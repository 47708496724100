import './PureCloudLogin.css'
import React, { useEffect, Fragment } from 'react'
import config from '../../../config'

const PureCloudLogin = () => {
    useEffect(() => {
        console.log('PureCloudLogin component')
        const queryStringData = {
            response_type: 'token',
            client_id: config.purecloud.clientId,
            redirect_uri: `${window.location.protocol}//${window.location.host}`
        }
        const param = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&')
        console.log(`Authorization request: ${param}`)
        window.location = `https://login.${sessionStorage.getItem('purecloud-csp-env')}/oauth/authorize?${param}`
    }, [])

    return (<Fragment></Fragment>)
}

export default PureCloudLogin