import React, {useContext, useState, useEffect} from 'react';
import Tableau from '../../js/tableau.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Toolbar from '../toolbar/Toolbar';
import AppContext from '../appContext/AppContext';

const Sidebar = (props) => {

    const [activeViz, setActiveViz] = useContext(AppContext);

    useEffect(() => {
        Tableau.loadViz(0);
    }, [])
      
 const initView = (event) => 
 {
    console.log(event);
    document.getElementById("filterView").innerHTML = event.currentTarget.getAttribute('data-view');
    let viz_name = Tableau.loadViz(event)
    setActiveViz(viz_name)
 }

 function alert1()
 {
    var r = document.querySelector(':root');    
    r.classList.toggle("sidebar-toggle");
 }

    return (
        
        <div>

            <p hidden id="filterView"></p>
            <div className="offcanvas offcanvas-start sidebar-nav bs-white" tabIndex="-1" id="sidebar">
                <div className="offcanvas-body p-0">
                    <nav className="navbar-dark">
                        <ul className="navbar-nav">

                            <li>
                                <a className="navbar-brand me-auto ms-lg-0 ms-3 text-uppercase fw-bold" id="test" href="#" onClick={alert1} ><img className="brand-logo" src={require('../../images/logo.jpg').default} /></a>
                                {/* <span className="customer-name">American Speciality Health</span> */}
                            </li>
                            <li className="line-custom">
                                <hr className="dropdown-divider bg-light" />
                            </li>
                            <li>
                                <div className="text-muted medium fw-bold px-3 mb-3">
                                    <span className="me-2 iconclr"><i className="bi bi-bar-chart"></i></span><span>Analytics</span>
                                </div>
                            </li>
                            <li>
                                <a className="nav-link px-3 sidebar-link" data-bs-toggle="collapse" href="#layouts">
                                    <span className="me-2 iconclr"><i className="bi bi-folder-plus"></i></span>
                                    <span>Dashboards</span>
                                    <span className="ms-auto">
                                        <span className="right-icon">
                                            <i className="bi bi-chevron-down"></i>
                                        </span>
                                    </span>
                                </a>
                                <div className="collapse" id="layouts">
                                    <ul className="navbar-nav ps-3">                                       
                                      <li>
                                            <a className="nav-link px-3" data-bs-toggle="collapse" href="#cc_dashboard">
                                                <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span><span>Contact Center Dashboard</span>
                                            </a>
                                     </li>
                                        <div className="collapse" id="cc_dashboard">
                                            <ul className="navbar-nav ps-3">
                                                <li>
                                                    <a href="#" className="nav-link px-3" data-view="Contact_Center_KPIs_Dashboard" view-title="Contact Center KPIs Dashboard" onClick={(event)=>initView(event)}>
                                                        <span className="me-2"><i className="bi bi-dash"></i></span>
                                                        <span>KPIs</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="nav-link px-3" data-view="Contact_Center_Interactions_Dashboard" view-title="Contact Center Interactions Dashboard" onClick={(event)=>initView(event)}>
                                                        <span className="me-2"><i className="bi bi-dash"></i></span>
                                                        <span>Interactions</span>
                                                    </a>
                                                </li>                                                
                                            </ul>
                                        </div>
                                        <li>
                                         <a href="#" className="nav-link px-3" data-view="Contact_Center_Analytics" view-title="Contact Center Analytics" onClick={(event)=>initView(event)}>
                                         <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span><span>Contact Center Analytics</span></a>
                                      </li>
                                        <li>
                                    <a href="#" className="nav-link px-3" view="0" data-view="Agent_Performance" view-title="Agent Performance Dashboard" onClick={(event)=>initView(event)}>
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Agent Performance</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="nav-link px-3" view="1" data-view="Agent_Queue_Performance" view-title="Agent Queue Performance Dashboard" onClick={(event)=>initView(event)}>
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Agent Queue Performance</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link px-3" data-view="Queue_Performance" view-title="Queue Performance Dashboard" data-bs-toggle="collapse" href="#queue_performance">
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Queue Performance</span>
                                    </a>
                                </li>
                                <div className="collapse" id="queue_performance">
                                    <ul className="navbar-nav ps-3">
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Performance_KPIs" view-title="Queue KPIs Performance Dashboard" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>KPIs</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Performance_Summary" view-title="Queue Performance Summary Dashboard" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Summary</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Performance_Threshold" view-title="Queue Performance Threshold Dashboard" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Threshold</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <li>
                                    <a className="nav-link px-3" data-view="Quality_Management" view-title="Quality Management Dashboard" data-bs-toggle="collapse" href="#queue_manage">
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Quality Management</span>
                                    </a>
                                </li>
                                <div className="collapse" id="queue_manage">
                                    <ul className="navbar-nav ps-3">
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Quality_Management_Performance" view-title="Quality Performance Management Dashboard" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Performance</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Quality_Management_Evaluation" view-title="Quality Evaluation Management Dashboard" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Evaluation</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}                                                              
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a className="nav-link px-3 sidebar-link" data-bs-toggle="collapse" href="#layouts2">
                            <span className="me-2 iconclr"><i className="bi bi-folder-plus"></i></span>
                            <span>Reports</span>
                            <span className="ms-auto">
                                <span className="right-icon">
                                    <i className="bi bi-chevron-down"></i>
                                </span>
                            </span>
                        </a>
                        <div className="collapse" id="layouts2">
                            <ul className="navbar-nav ps-3">
                                <li>
                                    <a href="#" className="nav-link px-3" data-view="Agent_Details_Activity_Report" view-title="Agent Details Activity Report" onClick={(event)=>initView(event)}>
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Agent Details Activity Report</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link px-3" data-bs-toggle="collapse" href="#agent_details_report">
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Agent Details Report</span>
                                    </a>
                                </li>
                                <div className="collapse" id="agent_details_report">
                                    <ul className="navbar-nav ps-3">
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Agent_Status_Report" view-title="Agent Status Activity Report" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Status Activity</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Agent_Interaction_Report" view-title="Agent Interaction Activity Report" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Interaction Activity</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <li>
                                    <a className="nav-link px-3" data-bs-toggle="collapse" href="#queue_details_report">
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Queue Details Report</span>
                                    </a>
                                </li>
                                <div className="collapse" id="queue_details_report">
                                    <ul className="navbar-nav ps-3">
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Summary_Report" view-title="Queue Summary Report" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Queue Summary</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Agent_Accepted_Report" view-title="Queue Agent Accepted Report" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Agent Accepted</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="nav-link px-3" data-view="Queue_Agent_Abandoned_Report" view-title="Queue Abandon Report" onClick={(event)=>initView(event)}>
                                                <span className="me-2"><i className="bi bi-dash"></i></span>
                                                <span>Queue Abandon</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <li>
                                    <a href="#" className="nav-link px-3" data-bs-toggle="collapse" data-view="Conversation_Details_Report" view-title="Conversation Details Report" onClick={(event)=>initView(event)}>
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Conversation Details Report</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="#" className="nav-link px-3" data-bs-toggle="collapse" data-view="Quality_Management_Report" view-title="Quality Management Report" onClick={(event)=>initView(event)}>
                                        <span className="me-2"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        <span>Quality Management Report</span>
                                    </a>
                                </li>  */}                               
                            </ul>
                        </div>
                    </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
      );
    }
export default Sidebar