import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import '../../css/bootstrap.min.css';
import '../../css/bootstrap.min.css.map';
import '../../css/style.css';
import '../../css/custom.css';
import './login.css';
import '../../js/tableau.js';
import '../../js/bootstrap.bundle.min.js';
import Toolbar from '../toolbar/Toolbar';


const TableauLogin = (props) => {

    const history = useHistory()
    
    const userAuth = async() =>
    {
       /*  let payload = '<tsRequest><credentials name="pradhap.gunasekaran@genesys.com" password="TableauGP@2022-A3S"><site contentUrl="toddtsaundersatgenesyscom" /></credentials></tsRequest>';
        const response = await fetch('https://prod-useast-a.online.tableau.com/api/3.11/auth/signin', {
        method: "POST", 
        body: payload,
        headers: {"Content-type": "application/xml"}
        })
        .then(async response => {
            debugger
            //response.json()
            let result = await response.text();
        }) 
        .then(json => console.log(json));  
        console.log(response);       */
        history.push('/home')
    }

 
    return (
        <div>
            <div className="login">
                <div className="form">
                    <form className="login-form">
                        <span className="material-icons">lock</span>
                        <input type="text" placeholder="username" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                        <input type="password" placeholder="password" required />
                        <button type='button' className="btn btn-secondary btn-custom" onClick={userAuth}>login</button>
                    </form>  
                </div>
            </div>
      </div>
      

);
    }

export default TableauLogin;