import config from '../config'


/**
 * Establishes an ICWS session with the given credentials.
 * Returns a promise
 * @param {string} userID 
 * @param {string} password 
 */
export const connect = async (userID, password) => {
    const body = JSON.stringify({
        __type: 'urn:inin.com:connection:icAuthConnectionRequestSettings',
        applicationName: config.pureconnect.applicationName,
        userID,
        password
    })

    const response = await fetch(`${config.pureconnect.baseUrl}/connection`, {
        method: 'POST',
        headers: {
            'Accept-Language': 'en-us'
        },
        body,
        credentials: 'include'
    })

    const responseJson = await response.json();

    if (response.ok) {
        sessionStorage.setItem('pureconnect-csrf-token', responseJson.csrfToken);
        sessionStorage.setItem('pureconnect-sessionid', responseJson.sessionId);
        sessionStorage.setItem('pureconnect-alternate-hosts', responseJson.alternateHostList.join('|'));
    }

    return responseJson;
}

/**
 * Terminates the specified ICWS session.
 * Returns a promise.
 */
export const disconnect = async () => {
    const response = await fetch(`${config.pureconnect.baseUrl}/${sessionStorage.getItem('pureconnect-sessionid')}/connection`, {
        method: 'DELETE',
        headers: {
            'ININ-ICWS-CSRF-TOKEN': sessionStorage.getItem('pureconnect-csrf-token')
        }
    })

    return response.json()
}

/**
 * Gets the connection state of the specified ICWS session.
 * Returns a promise.
 */
export const checkSession = async () => {
    const response = await fetch(`${config.pureconnect.baseUrl}/${sessionStorage.getItem('pureconnect-sessionid')}/connection`, {
        method: 'GET',
        headers: {
            'ININ-ICWS-CSRF-TOKEN': sessionStorage.getItem('pureconnect-csrf-token')
        }
    })

    return await response.json()
}

/**
 * Sends a heartbeat to keep the ICWS session active.
 * Returns a promise
 */
export const heartbeat = async () => {
    const response = await fetch(`${config.pureconnect.baseUrl}/${sessionStorage.getItem('pureconnect-sessionid')}/messaging/messages`, {
        method: 'GET',
        headers: {
            'ININ-ICWS-CSRF-TOKEN': sessionStorage.getItem('pureconnect-csrf-token')
        }
    })

    return await response.json()
}

/**
 * 
 * Returns a promise
 * @param {string} token The SSO token returned from opening SSO window and pulled off via window.postmessage
 */
export const ssoConnect = async (token) => {
    const body = JSON.stringify({
        __type: 'urn:inin.com:connection:singleSignOnTokenConnectionRequestSettings',
        applicationName: config.pureconnect.applicationName,
        singleSignOnToken: token
    })

    const response = await fetch(`${config.pureconnect.baseUrl}/connection`, {
        method: 'POST',
        headers: { 'Accept-Language': 'en-us' },
        body,
        credentials: 'include'
    })

    const responseJson = await response.json();

    if (response.ok) {
        sessionStorage.setItem('pureconnect-csrf-token', responseJson.csrfToken);
        sessionStorage.setItem('pureconnect-sessionid', responseJson.sessionId);
    }

    return responseJson;
}

/**
* Gets identity provider information from PureConnect
* Returns an identity provider object (data) that contains identityProviderId (string/GUID), displayName (string), builtInIdentityProvider (bool)
* and a bool 'ok' whether or not the request suceeded.
* @param {string} displayName The display name of the SSO provider that will be used.
*/
export const getIdentityProviderInfo = async (displayName) => {
    try {
        const responseServerInfo = await fetch(`${config.pureconnect.baseUrl}/connection/server-info?singleSignOnCapabilities=saml2Post`, {
            method: 'GET',
            headers: {
                'Accept-Language': 'en-us',
            }
        });
        const responseServerInfoJson = await responseServerInfo.json();
        const identityProviders = responseServerInfoJson.authentication.identityProviders.filter(x => {
            if (x.displayName === displayName)
                return x.identityProviderId
            else return undefined
        });

        let result = {
            data: identityProviders[0],
            ok: identityProviders.length > 0
        };
        return result;
    }
    catch (err) {
        let result = {
            error: err,
            ok: false
        }

        return result;
    }
}

export default {
    connect,
    disconnect,
    checkSession,
    heartbeat,
    ssoConnect,
    getIdentityProviderInfo
}
