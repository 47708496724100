import React from 'react';
import '../../css/bootstrap.min.css';
import '../../css/bootstrap.min.css.map';
import '../../css/style.css';
import '../../css/custom.css';
import '../../js/tableau.js';
import '../../js/bootstrap.bundle.min.js';
import Toolbar from '../toolbar/Toolbar';


const Header = (props) => {
    
 
    return (
        
        <div className="row">
            <div className="col-md-6 mb-3">
                <h4 className="text-left" id="dashboard-title">Contact Center KPIs Dashboard</h4>
            </div>
          <Toolbar>
          </Toolbar>
        </div>
 
);
    }

export default Header;