import React from "react";
import {Button,Modal, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './subscribe.css'

const SubscribeModal = (props) => {  
      
    return (

      <Modal isOpen={props.showSubscribe} >
                 
                 <div className="modal-header custom-head">
                        <h5 className="modal-title" id="subscribeModal">Subscribe</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" data-filter="APFilter" onClick= {props.toggle} aria-label="Close"></button>
                    </div>
        <ModalBody>
                  <FormGroup>
                    <Label for="subscribeUsers">Subscribe Users</Label>
                    <Input type="textarea" name="text" className="custom-font"/>
                </FormGroup>
                <FormGroup check>
                    <Label check className="custom-label custom-font">
                      <Input type="checkbox"/>{' '}
                      Subscribe me
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label for="View Text" className="custom-label">Include</Label>
                    <Input type="select" name="viewInclude" className="custom-font">
                      <option>This View</option>
                      <option>Entire Workbook</option>
                    </Input>
                    <Label check className="custom-label custom-font">
                      <Input type="checkbox"/>{' '}
                      Don't send if the view is empty
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label for="Format" className="custom-label">Format</Label>
                    <Input type="select" name="select" className="custom-font">
                      <option>Image</option>
                      <option>PDF</option>
                      <option>Image and PDF</option>
                    </Input>                    
                </FormGroup>
                <FormGroup>
                    <Label for="Subject" className="custom-label">Subject</Label>
                    <Input type="text" name="subject" className="custom-font" />  
                </FormGroup>
                <FormGroup>
                    <Label for="Message" className="custom-label">Message (Optional)</Label>
                    <Input type="textarea" name="text" placeholder="Add a custom message" className="custom-font" />
                </FormGroup>
                <FormGroup>
                    <Label for="Frequency" className="custom-label">Frequency</Label>
                    <Input type="select" name="frequency" className="custom-font">
                      <option>On Selected Schedule</option>
                      <option>When Data Refreshes</option>                      
                    </Input>                    
                </FormGroup>
        </ModalBody>
         <ModalFooter>
                <div >
                        <button type="button" className="btn btn-secondary btn-custom" onClick= {props.toggle} >Cancel </button>
                         <button type="button" className="btn btn-secondary btn-custom">Subscribe</button>
                    </div>
         </ModalFooter>
 </Modal>
      
    );
  }
  
  export default SubscribeModal;