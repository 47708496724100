import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import config from '../config'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import PureCloudLogin from '../components/login/purecloud/PureCloudLogin'
import PureConnectLogin from '../components/login/pureconnect/PureConnectLogin'
import Home from '../components/home/Home'
import Main from '../components/Main'
import Unauthorized from '../components/unauthorized/Unauthorized'
import Filter from '../components/filter/filterModal'
import TableauLogin from '../components/tableau-login/TableauLogin'


const history = createBrowserHistory()
const LoginPage = PureCloudLogin
const AppRouter = () => (
    <Router history={history}>
        <Switch>
            <PublicRoute path="/" exact={true} component={Main} history={history} />
            <PublicRoute path="/login" component={LoginPage} />
            <PublicRoute path="/unauthorized" component={Unauthorized} />
            <PrivateRoute path="/home" component={Home} history={history} />
            <PrivateRoute path="/agentfilter" component={Filter} history={history} />
            <PrivateRoute path="/tableau" component={TableauLogin} history={history} />  
                                        
        </Switch>
    </Router>
)

export default AppRouter