import './PureConnectLogin.css'

import React from 'react'
import { ReactSVG } from 'react-svg'
import { Container, Row, Col, Button, Form, Label, Input, FormGroup, Alert } from 'reactstrap'

import { connect } from '../../../services/pureconnect'
import { ssoConnect } from '../../../services/pureconnect'
import { getIdentityProviderInfo } from '../../../services/pureconnect'
import config from '../../../config'

//TODO: update this to get switchover status firsto build hosts list...or loop through primary/backup to find which one to use.

class PureConnectLogin extends React.Component {

    state = {
        username: '',
        password: '',
        showLoginAlert: false,
        alertMessage: '',
    }
    handleEnvironmentChanged = (e) => {
        const selectedEnvironment = e.target.value
        this.setState({ selectedEnvironment })
    }
    onSubmit = async (e) => {
        e.preventDefault()
        try {
            if (config.pureconnect.identityProviderName === '') {
                //using good ol' CIC auth
                const response = await connect(this.state.username, this.state.password);
                if (response.errorId) {
                    //could display login error if we wanted to
                    this.setState({ showLoginAlert: true, alertMessage: response.message })
                    setTimeout(() => {
                        this.setState({ showLoginAlert: false })
                    }, 5000);
                }
                else {
                    console.log('Successful login!');
                    this.props.history.push('/home');
                }
            } else {
                //Using single sign on or windows auth
                const response = await getIdentityProviderInfo(config.pureconnect.identityProviderName);
                if (response.ok === true) {
                    const ssoUrl = `${config.pureconnect.baseUrl}/connection/single-sign-on/identity-providers/${response.data.identityProviderId}`;
                    const ssoParams = '?singleSignOnCapabilities=saml2Post&webBrowserApplicationOrigin=' + encodeURIComponent(window.location.href);

                    //need second parameter in window.open otherwise opens as new tab. TODO: Need to figure out to close this bitch when we're done with it.
                    this.ssoFrame = window.open(ssoUrl + ssoParams, 'SSO Auth', 'menubar=no,resizable=no,scrollbars=no,menubar=no,height=300,width=400,top=150,left=150');
                }
                else {
                    this.setState({ showLoginAlert: true, alertMessage: `Login Failed! ${response.error}` })
                    setTimeout(() => {
                        this.setState({ showLoginAlert: false })
                    }, 5000);
                }
            }

        } catch (err) {
            console.error(err);
        }
    }

    componentDidMount() {
        this.ssoFrame = {};
        if (config.pureconnect.identityProviderName !== '') {
            window.addEventListener('message', this.handleSsoFrame.bind(this), false)
        }
    }

    async handleSsoFrame(e) {
        if (e.origin.indexOf(config.pureconnect.primary) > -1) {
            let message = JSON.parse(e.data);

            if (message.data.errorId !== '') {
                this.setState({ showLoginAlert: true })
                setTimeout(() => {
                    this.setState({ showLoginAlert: false, alertMessage: message.data.errorMessage })
                }, 5000);
            }

            if (message.key === 'icwsSsoComplete' && message.data.httpStatus === 200) {
                if (message.data.errorId !== '') {
                    this.setState({ showLoginAlert: true, alertMessage: message.data.errorMessage })
                    await this.ssoFrame.close();
                    setTimeout(() => {
                        this.setState({ showLoginAlert: false })
                    }, 5000);
                } else {
                    //Success!
                    await ssoConnect(message.data.token);
                    await this.ssoFrame.close();
                    this.props.history.push('/home');
                }
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.handleSsoFrame);
    }

    render() {
        let loginFields = (
            <div className="pureconnect-login-input-group">
                <FormGroup>
                    <Label>Username</Label>
                    <Input
                        type="text"
                        placeholder="Username"
                        value={this.state.username}
                        onChange={e => { this.setState({ username: e.target.value }) }} />
                </FormGroup>
                <FormGroup>
                    <Label>IC Password</Label>
                    <Input type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={e => { this.setState({ password: e.target.value }) }} />
                </FormGroup>
            </div>
        );

        return (
            <div className="pureconnect-login-container">
                <Container className="pureconnect-login-vert-center">
                    <Row>
                        <Col>
                            <h2 className="pureconnect-login-header">Genesys PureConnect Template</h2>
                            <ReactSVG path="./resources/pureconnect/pureconnect-color-text.svg" className="pureconnect-login-svg" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={this.onSubmit}>
                                {config.pureconnect.identityProviderName === '' ? loginFields : ''}
                                <Button className="pureconnect-login-input-group" type="submit" color="secondary" block>Log In</Button>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert className="pureconnect-login-alert" color="danger" isOpen={this.state.showLoginAlert}>
                                {this.state.alertMessage}
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default PureConnectLogin