import React, { useEffect, Fragment, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { getParameterByName } from '../services/utils'
import config from '../config';
import { getOrganization } from '../services/purecloud'
import subscription from '../services/subscription'
import AppContext from './appContext/AppContext';

const Main = () => {
    const location = useLocation()
    const history = useHistory()
   

    useEffect(() => {
        const init = async () => {
        let env = new URLSearchParams(location.search).get('environment')
            if (!env) env = 'usw2.pure.cloud'
            sessionStorage.setItem('purecloud-csp-env', env)
            console.log('Main.purecloud.init:', env)

            if (getParameterByName('access_token')) sessionStorage.setItem('purecloud-csp-token', getParameterByName('access_token'))
            if (sessionStorage.getItem('purecloud-csp-token')) {
                try {
                    const organization = await getOrganization(sessionStorage.getItem('purecloud-csp-env'), sessionStorage.getItem('purecloud-csp-token'))
                    //const response = await subscription.validateGCApp(sessionStorage.getItem('purecloud-csp-token'), sessionStorage.getItem('purecloud-csp-env'), organization.id, process.env.SOLUTION)
                    history.push('/home')
                } catch (error) {
                  //  if (error.status === 401 && error.code === 'bad.credentials') {
                        //handle expired token
                    //    sessionStorage.removeItem('purecloud-csp-token')
                      //  console.log('Invalid token - Redirecting to login page');
                       // history.push('/login')
                    //} else {
                        history.push('/unauthorized', error.message)
                //    }
                }
            } else history.push('/login')
      
        // eslint-disable-next-line
    }
    init()
}, [])

    return (<Fragment></Fragment>)
}

export default Main