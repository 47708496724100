import React, { useState, useContext, useEffect } from "react";
import {Button,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import moment from "moment";

import '../../css/bootstrap.min.css';
import '../../css/bootstrap.min.css.map';
import '../../css/style.css';
import '../../css/custom.css';
import $ from 'jquery';

import Tableau from '../../js/tableau.js';
import '../../js/bootstrap.bundle.min.js';
import AppContext from '../appContext/AppContext';
import config from "../../config";




const Filter = (props) => { 
    const [activeViz, setActiveViz] = useContext(AppContext);
    const [filterAvailable, setfilterAvailable] = useState({})
    //Filter Related Variables
    const [agentNameSelected, setAgentNameSelected] = useState(props.filterDefaultList['Agent Name']? props.filterDefaultList['Agent Name'] : [])
    const [agentNameList, setAgentNameList] = useState(props.filterValuesList['Agent Name']? props.filterValuesList['Agent Name'] : [])
    
    const [MediaTypeSelected, setMediaTypeSelected] = useState(props.filterDefaultList['Media Type']? props.filterDefaultList['Media Type'] : [])
    const [MediaTypeList, setMediaTypeList] = useState(props.filterValuesList['Media Type']? props.filterValuesList['Media Type'] : [])

    const [QueueNameSelected, setQueueNameSelected] = useState(props.filterDefaultList['Queue Name']? props.filterDefaultList['Queue Name'] : [])
    const [QueueNameList, setQueueNameList] = useState(props.filterValuesList['Queue Name']? props.filterValuesList['Queue Name'] : [])

    const [CustomerSegmentSelected, setCustomerSegmentSelected] = useState(props.filterDefaultList['Customer Segment']? props.filterDefaultList['Customer Segment'] : [])
    const [CustomerSegmentList, setCustomerSegmentList] = useState(props.filterValuesList['Customer Segment']? props.filterValuesList['Customer Segment'] : [])

    const [ServiceTypeSelected, setServiceTypeSelected] = useState(props.filterDefaultList['Service Type']? props.filterDefaultList['Service Type'] : [])
    const [ServiceTypeList, setServiceTypeList] = useState(props.filterValuesList['Service Type']? props.filterValuesList['Service Type'] : [])

    const [ServiceSubTypeSelected, setServiceSubTypeSelected] = useState(props.filterDefaultList['Service Subtype']? props.filterDefaultList['Service Subtype'] : [])
    const [ServiceSubTypeList, setServiceSubTypeList] = useState(props.filterValuesList['Service Subtype']? props.filterValuesList['Service Subtype'] : [])

    const [FormNameSelected, setFormNameSelected] = useState(props.filterDefaultList['Form Name']? props.filterDefaultList['Form Name'] : [])
    const [FormNameList, setFormNameList] = useState(props.filterValuesList['Form Name']? props.filterValuesList['Form Name'] : [])

    //const [ConversationIDList, setConversationIDList] = useState(props.filterValuesList['Conversation ID']? props.filterValuesList['Conversation ID'] : '' )
    const [ConversationIDSelected, setConversationIDSelected] = useState(props.parameterCurrentList['Conversation ID'] ? props.parameterCurrentList['Conversation ID'] : {})
    
    const [WrapupNameSelected, setWrapupNameSelected] = useState(props.filterDefaultList['Wrap Up Name ']? props.filterDefaultList['Wrap Up Name '] : [])
    const [WrapupNameList, setWrapupNameList] = useState(props.filterValuesList['Wrap Up Name ']? props.filterValuesList['Wrap Up Name '] : [])

    const [ColumnPickerSelected, setColumnPickerSelected] = useState(props.filterDefaultList['Measure Names']? props.filterDefaultList['Measure Names'] : [])
    const [ColumnPickerList, setColumnPickerList] = useState(props.filterValuesList['Measure Names']? props.filterValuesList['Measure Names'] : [])

    const [TopicNameSelected, setTopicNameSelected] = useState(props.filterDefaultList['Topic Name']? props.filterDefaultList['Topic Name'] : [])
    const [TopicNameList, setTopicNameList] = useState(props.filterValuesList['Topic Name']? props.filterValuesList['Topic Name'] : [])

    const PresetDateoptions = [
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'This Week', label: 'This Week' },
        { value: 'Last Week', label: 'Last Week' },
        { value: 'This Month', label: 'This Month' },
        { value: 'Last Month', label: 'Last Month' },
        { value: 'This Quarter', label: 'This Quarter' },
        { value: 'Last Quarter', label: 'Last Quarter' },
        { value: 'This Year', label: 'This Year' },
        { value: 'Last Year', label: 'Last Year' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Last 60 Days', label: 'Last 60 Days' },
        { value: 'Last 90 Days', label: 'Last 90 Days' },
        { value: 'Custom Date Range', label: 'Custom Date Range' }
      ];
      const [dateSelected, setDateSelected] = useState(props.parameterCurrentList['Date Filter'] ? props.parameterCurrentList['Date Filter'] : {})    
      const [startDateSelected, setStartDateSelected] = useState(moment(props.parameterCurrentList['Start Date'].label).format('YYYY-MM-DD') ? moment(props.parameterCurrentList['Start Date'].label).format('YYYY-MM-DD') : {})
      const [endDateSelected, setEndDateSelected] = useState(moment(props.parameterCurrentList['End Date'].label).format('YYYY-MM-DD') ? moment(props.parameterCurrentList['End Date'].label).format('YYYY-MM-DD') : {})      

    useEffect(() => {
        
        setfilterAvailable(config.filters[activeViz])
    }, [activeViz])

      return (
            <Modal isOpen={props.showModal} >
                 
                 <div className="modal-header custom-head">
                        <h5 className="modal-title" id="staticBackdropLabel">Add Filter</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" data-filter="APFilter" onClick= {props.toggle} aria-label="Close"></button>
                    </div>
                
            <ModalBody>
                <div >
                {filterAvailable['Agent_Name'] ?   
                <div className="row">
                        <div className="col-3 modal-label">
                            <div style={{paddingBottom:'15px'}}>
                                Agent Name 
                            </div>
                        </div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="AgentFilter" className="dropdown multi-select-width"
                             options={agentNameList}
                            value={agentNameSelected}
                            selectionLimit={-1}
                            onChange={e => {setAgentNameSelected(e)
                                            if((e.map(e => e.value)).length == agentNameList.length)
                                                Tableau.applyFiltertoWorkbook("All","Agent Name")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Agent Name")
                                             //  Tableau.retrieveDefaultFilterValuesFromWorkbook()
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div> : null }

                    {filterAvailable['Media_Type'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Media Type</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="MediaTypeFilter" className="dropdown multi-select-width"
                             options={MediaTypeList}
                            value={MediaTypeSelected}
                            onChange={e => {setMediaTypeSelected(e)
                                            if((e.map(e => e.value)).length == MediaTypeList.length)
                                                Tableau.applyFiltertoWorkbook("All","Media Type")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Media Type")
                                             //  Tableau.retrieveDefaultFilterValuesFromWorkbook()
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null } 

                     {filterAvailable['Queue_Name'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Queue Name</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="QueueNameFilter" className="dropdown multi-select-width"
                             options={QueueNameList}
                            value={QueueNameSelected}
                            onChange={e => {setQueueNameSelected(e)
                                            if((e.map(e => e.value)).length == QueueNameList.length)
                                                Tableau.applyFiltertoWorkbook("All","Queue Name")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Queue Name")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Customer_Segment'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Customer Segment</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="CustomerSegmentFilter" className="dropdown multi-select-width"
                             options={CustomerSegmentList}
                            value={CustomerSegmentSelected}
                            onChange={e => {setCustomerSegmentSelected(e)
                                            if((e.map(e => e.value)).length == CustomerSegmentList.length)
                                                Tableau.applyFiltertoWorkbook("All","Customer Segment")
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Customer Segment")
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Service_Type'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Service Type</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="ServiceTypeFilter" className="dropdown multi-select-width"
                             options={ServiceTypeList}
                            value={ServiceTypeSelected}
                            onChange={e => {setServiceTypeSelected(e)
                                            if((e.map(e => e.value)).length == ServiceTypeList.length)
                                                Tableau.applyFiltertoWorkbook("All","Service Type")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Service Type")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Service_Sub_Type'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Service SubType</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="ServiceSubTypeFilter" className="dropdown multi-select-width"
                             options={ServiceSubTypeList}
                            value={ServiceSubTypeSelected}
                            onChange={e => {setServiceSubTypeSelected(e);
                                            if((e.map(e => e.value)).length == ServiceSubTypeList.length)
                                                Tableau.applyFiltertoWorkbook("All","Service Subtype");
                                            else
                                                Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Service Subtype");
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Conversation_Id'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Conversation ID</div>
                        <div className="col-9 modal-filters">
                        <input type="text" className="form-control multi-select-width" id="ConversationIDFilter" name="conversation_id" style={{padding: '8px'}} value= {ConversationIDSelected.label} onKeyUp= {e => {setConversationIDSelected(e.target.value)
                                               // Tableau.applyParameterToWorksheet("Date Filter",e.value)
                                                Tableau.applyParameterToWorksheet("Conversation ID",e.target.value)
                                        }}/>
                        </div>
                    </div>               : null }  

                    {filterAvailable['Wrap_Up_Name'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Wrap Up Name</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="WrapUpFilter" className="dropdown multi-select-width"
                             options={WrapupNameList}
                            value={WrapupNameSelected}
                            onChange={e => {setWrapupNameSelected(e)
                                            if((e.map(e => e.value)).length == WrapupNameList.length)
                                                Tableau.applyFiltertoWorkbook("All","Wrap Up Name")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Wrap Up Name")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Column_Picker'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Column Picker</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="ColumnPickerFilter" className="dropdown multi-select-width"
                             options={ColumnPickerList}
                            value={ColumnPickerSelected}
                            onChange={e => {setColumnPickerSelected(e)
                                            if((e.map(e => e.value)).length == ColumnPickerList.length)
                                                Tableau.applyFiltertoWorkbook("All","Measure Names")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.label),"Measure Names")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Form_Name'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Form Name</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="FormNameFilter" className="dropdown multi-select-width"
                             options={FormNameList}
                            value={FormNameSelected}
                            onChange={e => {setFormNameSelected(e)
                                            if((e.map(e => e.value)).length == FormNameList.length)
                                                Tableau.applyFiltertoWorkbook("All","Form Name")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Form Name")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                    {filterAvailable['Topic_Name'] ?  
                    <div className="row">
                        <div className="col-3 modal-label">Topic Name</div>
                        <div className="col-9 modal-filters">
                        <MultiSelect id="TopicNameFilter" className="dropdown multi-select-width"
                             options={TopicNameList}
                            value={TopicNameSelected}
                            onChange={e => {setTopicNameSelected(e)
                                            if((e.map(e => e.value)).length == TopicNameList.length)
                                                Tableau.applyFiltertoWorkbook("All","Topic Name")
                                                
                                            else
                                               { Tableau.applyFiltertoWorkbook(e.map(e => e.value),"Topic Name")
                                   
                                                }
                                        }}
                            labelledBy="Select"
                            />
                        </div>
                    </div>               : null }  

                     {filterAvailable['PreSet_Date'] ?     
                        <div className="row">
                            <div className="col-3 modal-label">Pre-Set Date</div> 
                            <div className="col-9 modal-filters">    
                                <Select  className="multi-select-width"
                                options = {PresetDateoptions}
                                value = {dateSelected}
                                onChange = {e=>{setDateSelected(e)
                                    Tableau.applyParameterToWorksheet("Date Filter",e.value)     
                                }}      
                                />
                            </div>
                        </div> : null}

                        {filterAvailable['Start_Date'] ?
                        <div className="row">   
                            <div className="col-3 modal-label">
                                <label htmlFor="start_date">Start Date</label>
                            </div>                     
                            <div className="col-9 modal-filters">
                                <input type="date" className="form-control" id="start_date" name="start_date" disabled style={{padding: '8px'}} value= {startDateSelected} onChange={e=>{setStartDateSelected(e.target.value)
                                    Tableau.applyParameterToWorksheet("Start Date",e.target.value)       
                                }}  />
                            </div>
                        </div> : null}
                        {filterAvailable['End_Date'] ?
                        <div className="row">   
                        <div className="col-3 modal-label">
                            <label>End Date</label>
                        </div>                     
                        <div className="col-9 modal-filters">
                            <input type="date" className="form-control" id="end_date" name="end_date" disabled style={{padding: '8px'}} value= {endDateSelected} onChange={e=>{
                                    setEndDateSelected(e.target.value)
                                    Tableau.applyParameterToWorksheet("End Date",e.target.value)      
                                }}/>
                        </div>
                    </div>
                    : null}

                    </div></ModalBody>
            <ModalFooter>
                <div >
                        <button type="button" className="btn btn-secondary btn-custom" style={{marginRight: '200px'}} onClick= {props.toggle}  >Close </button>
                        {/* <button type="button" className="btn btn-secondary btn-custom filter-btn">Filter</button> */}
                    </div>
                    </ModalFooter>
        </Modal>
        )
}
export default Filter;
